.InputLabel {
  margin-bottom: -1em;
  padding-bottom: 1.406em;
  width: 100%;
}
.InputLabel .input-label-title {
  display: inline-table;
  line-height: 1.4em;
  letter-spacing: -0.025em;
}
.InputLabel .input-label-title-big {
  display: inline-table;
  line-height: 1.4em;
  letter-spacing: -0.025em;
}
.InputLabel.error .input-label-title {
  color: #9f3a38;
  display: inline;
}
.InputLabel.error .input-label-title .error {
  color: #9f3a38;
  text-align: right;
  float: right;
  padding-top: 0.15em;
}

.InputLabel .input-label-content {
  padding-top: 0.3em;
}

.InputLabel.error .ui.labeled.input > .label {
  background-color: #9f3a38;
}
