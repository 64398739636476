.input-picture {
  text-align: center;
  vertical-align: center;
  height: 91px;
  width: 91px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.input-picture .content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-picture .picture-pic {
  height: 102%;
  width: 102%;
  object-fit: cover;
}

.inner-div {
  position: absolute;
}
