$green: var(--primary-color);
$greenActive: var(--primary-color-active);
$greenHover: var(--primary-color-hover);
$black: #373737;
$blackDark: #000000;
$lightGray: #f7f7f7;
$gray: #ddd;
$grey: #ddd;
$greyActive: #ccc;
$greyHover: #ccc;
$red: #f55353;
$yellow: #fff383;
$blue: #3399f7;
$orange: #ffa619;
$purple: #be82bd;

$boxBorderColor: $gray;
$boxBackgroundColor: $lightGray;

$textFontSizeMassive: 4.5rem; //72px
$textFontSizeExtra: 3.25rem; //52px
$textFontSizeHuge: 2.25rem; //36px
$textFontSizeBig: 1.375rem; //22px
$textFontSizeLarge: 1.125rem; //18px
$textFontSizeNormal: 1rem; //16px
$textFontSizeSmall: 0.889rem; //14px
$textFontSizeTiny: 0.778rem; //12px

// $commonFont: ObjectSans, Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
$commonFont: "Satoshi", sans-serif;
$specialFont: "Satoshi", sans-serif;
