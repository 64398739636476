.input-cover {
  text-align: center;
  vertical-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  width: 100%;
  position: relative;
}

.input-cover .content{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-cover .cover-pic{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.inner-div {
  position: absolute;
}