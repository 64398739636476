.cover-picture {
  border: 1px solid rgba(0, 0, 0, 0.25);
  width: 600px;
  height: 350px;
  object-fit: cover;
}

.media-picker {
  width: calc(33% - 8px);
  aspect-ratio: 1/1;
  border: 1px solid lightgrey;
  border-radius: 4px;
  position: relative;
  img {
    border-radius: 4px;
  }
}
.media-picker-button {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.media-picker .ui.image {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}
.media-picker .ui.image img {
  height: inherit;
  width: 100%;
  object-fit: cover;
}
.media-picker-progress.ui.progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: 0;
}
