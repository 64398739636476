@font-face {
  font-family: "Satoshi";
  font-weight: 400;
  src: url("../../assets/fonts/Satoshi-Regular.otf") format("truetype");
}
@font-face {
  font-family: "Satoshi";
  font-weight: 500;
  src: url("../../assets/fonts/Satoshi-Medium.otf") format("truetype");
}
@font-face {
  font-family: "Satoshi";
  src: url("../../assets/fonts/Satoshi-Bold.otf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "value-serif-regular";
  src: url("../../assets/fonts/Value Serif Pro Regular.ttf") format("truetype");
  font-weight: 600;
}

:root {
  --primary-color: #0f1216 !important;
  --primary-variant-color: #d8ff5f;
  --primary-variant-color-alpha-60: #d8ff5f99;
  --secondary-color: #adcc4c;
  --primary-font: "value-serif-regular";
  --secondary-font: "Satoshi", sans-serif;
}

::-moz-selection {
  background-color: var(--primary-variant-color-alpha-60);
}

::selection {
  background-color: var(--primary-variant-color-alpha-60);
}

.ui.button.rounded,
.ui.button.green {
  border-radius: 4px !important;
  border: 1px solid transparent;
}

.ui.button.green:not(.disabled) {
  background-color: var(--primary-variant-color) !important;
  border: 1px solid var(--secondary-color);
  color: var(--primary-color) !important;
}

.title,
.black.bold {
  font-family: var(--primary-font) !important;
  font-weight: 600 !important;
}

.text:not(.black.bold) {
  font-family: var(--secondary-font) !important;
  font-weight: 400 !important;
}
