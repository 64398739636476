.dropdown-button {
  display: flex;
}
.dropdown-button-with-join {
  display: flex;
}
@media only screen and (max-width:426px) {
  .dropdown-button-with-join .ui.green.button {
    width: -webkit-fill-available;
    margin-bottom: 1em;
  }
}
