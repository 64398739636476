.FormGrid.ui.grid>.row {
  padding-top:0.2em; 
  padding-bottom:0.6em;
}

.FormGrid.ui.grid>.row>.column {
  padding-left:0.2em; 
  padding-right:0.2em;
}

.FormGrid.ui.grid>.row>.column:first-child {
  padding-left:0; 
}

.FormGrid.ui.grid>.row>.column:last-child {
  padding-right:0;
}
