.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.clipboardButton {
  width: 100%;
  display: flex;
  .field {
    width: 100%;
    margin-bottom: 0px !important;
    background-color: #f7f7f7;
    margin-right: 5px !important;
  }
}
