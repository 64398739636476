.ui.container.GroupSortItem:hover {
  transform: scale(1.02, 1.02);
  box-shadow: 1px 1px 6px 3px rgba(0,0,0,0.015), 
    -1px -1px 6px 3px rgba(0,0,0,0.015);
  transition: .2s;
  cursor: pointer;
}
.GroupSortItem:hover .ui.header {
  text-decoration-line: underline;
}
.absolute {
  position: absolute;
  top: -2.7em;
  left: -4em;
  padding: 2em;
}
