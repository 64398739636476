.group-info {
  padding: 1.5em 0;
  display: flex;
  align-content: center;
}

.group-info .detail-content {
  flex-grow: 1;
}

.group-info:hover > .detail-content .Name {
  background-size: 100% 100%;
  cursor: pointer;
}

.group-info .detail-content .Name {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(45%, transparent),
    color-stop(10%, #fff383)
  );
  background-image: linear-gradient(180deg, transparent 45%, #fff383 0);
  background-size: 0 100%;
  background-repeat: no-repeat;
  text-decoration: none;
  -webkit-transition: background-size 0.4s ease;
  transition: background-size 0.4s ease;
  width: fit-content;
}

.group-info .right-content {
  margin: auto;
  white-space: pre-line;
  display: -webkit-inline-box;
}

.group-info .right-button {
  cursor: auto;
  padding-bottom: 46px;
  white-space: pre-line;
  display: -webkit-inline-box;
}

@media only screen and (max-width: 766px) {
  .group-info {
    display: block;
  }
  .group-info .avatar-content {
    padding-bottom: 1em;
  }
  .group-info .detail-content {
    display: inline-block;
  }
  .group-info .right-button {
    padding-top: 1em;
    padding-bottom: 0;
    margin-left: initial;
    display: block;
  }
  .group-info .publish-button {
    margin-bottom: 1em;
  }
}
