@import "styles/definitions.scss";

.horizontal_select {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  height: fit-content;
}

.horizontal_select_option.not_selected {
  transition-duration: 0.2s;
  min-width: 49%;
  max-width: 49%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  .horizontal_select_option_text {
    color: $black;
  }
}

.horizontal_select_option.not_selected:hover {
  transform: scale(1.005);
  border: 1px solid $green;
  .horizontal_select_option_text {
    color: $green;
  }
}

.horizontal_select_option.selected {
  transition-duration: 0.2s;
  min-width: 49%;
  max-width: 49%;
  background-color: $green;
  border: 1px solid $green;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  .horizontal_select_option_text {
    color: white;
  }
}

.horizontal_select_option.selected:hover {
  transform: scale(1.005);
  background-color: $green;
  border: 1px solid $green;
  .horizontal_select_option_text {
    color: white;
  }
}
