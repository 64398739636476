.ItemInfo.GroupSale .item-right-content {
  text-align: right;
}

.ItemInfo.GroupSale .item-right-content div:first-child {
  color: black;
  font-size: 1.3em;
}

.ItemInfo.GroupSale .item-right-content div:last-child {
  font-size: 0.5;
}

.group-sale-item {
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 1.5em;
  margin-bottom: 2em;
  margin-top: 1em;
}
@media only screen and (max-width: 426px) {
  .group-sale-item {
    padding: 1.5em 1em;
    margin-top: 0;
    margin-left: -1em;
    margin-right: -1em;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    border-left: none;
    border-right: none;
  }
}
.group-sale-item-group {
  display: flex;
}
.group-sale-item-price {
  display: flex;
  padding-top: 0.5em;
}
.membership-price-description-box {
  height: 16px;
  margin-top: 0.5em;
}
.membership-price-description-content {
  position: absolute;
}
.sale-item-label-wraper {
  position: relative;
}
.sale-item-label {
  position: absolute;
  right: 0;
  top: 0.6em;
  background-color: var(--primary-color);
  padding: 0.3em 1em 0.3em 0.5em;
  min-width: 98px;
}
.triangle-1 {
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-right: 22px solid var(--primary-color);
  border-bottom: 15px solid transparent;
  position: absolute;
  right: 98px;
  top: 8px;
  z-index: 2;
}
.triangle-2 {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-right: 22px solid var(--primary-color);
  border-bottom: 0px solid transparent;
  position: absolute;
  right: 98px;
  top: 21px;
  z-index: 2;
}
