$modal-space: 20px;

.main-modal {
  &.modal {
    background-color: #ffffff !important;
    position: relative;
    max-width: 810px;

    &.big {
      max-width: 1100px;
    }

    .main-modal-row {
      float: left;
      width: 100%;

      .Line {
        display: flex;
        margin-bottom: 1em;
        flex-direction: row;

        .line-key {
          margin-right: 5px;
        }
      }
    }

    .close-icon,
    i.icon {
      position: absolute;
      right: $modal-space;
      top: $modal-space;
      z-index: 1;
    }

    .header {
      padding-left: $modal-space !important;
      padding-right: $modal-space !important;
      width: 100%;
      float: left;

      .header-info {
        padding-left: ($modal-space * 4);
        position: relative;
        float: left;
        width: 100%;

        .header-info-title {
          width: 100%;
          float: left;
          font-weight: 600;
          font-size: 16px;
          letter-spacing: -0.02em;
          color: #000000;
          height: 16px;
          line-height: 16px;
          margin-top: 2px;
        }

        .header-info-sub-title {
          width: 100%;
          float: left;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          margin-top: 4px;
          height: 16px;
          letter-spacing: -0.02em;
          color: #000000;
          opacity: 0.54;
        }

        .header-info-image-container {
          width: ($modal-space * 2);
          height: ($modal-space * 2);
          content: "";
          position: absolute;
          top: 0;
          left: $modal-space;
          display: flex;
          align-items: center;
          flex-direction: row;
          border-radius: 4px;
          flex-flow: row;

          .header-info-image {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }

    .main-modal-content {
      float: left;
      width: 100%;
      padding-left: $modal-space;
      padding-right: $modal-space;
      color: #373737;

      .modal-content {
        max-width: 670px;
        margin: auto;
        width: 100%;

        .divider {
          float: left;
          width: 100%;
        }
      }

      &.full {
        .modal-content {
          max-width: 100%;
        }
        padding-bottom: $modal-space;
      }
    }
  }
}
