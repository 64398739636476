@import 'styles/definitions.scss';

.InputFile {
  position: relative;
  border: 1px solid rgba($color: #000000, $alpha: 0.24);
  padding: 10px;
  width: 100%;
  min-height: 200px;
  background-size: cover;
  background-position: center;
  transition: background-image 2s ease-in-out;
  border-radius: 4px;
}

.InputFile.error {
  border-color: #9f3a38;
}

.InputFile section {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.InputFile section .ui.button.remove {
  position: absolute;
  top: 10px;
  right: 0;
  padding: 0;
  min-width: unset;
  background-color: transparent;
}

.InputFile section .filename {
  display: inline-block;
  text-align: center;
  max-width: 70%;
  padding: 1em;
  max-height: 50px;
}

.InputFile section > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  outline: none;
  height: 100%;
}

.InputFile section > div svg {
  width:3em;
  height:3em;
}

.InputFile section > div svg path {
  fill: #3B89E2;
}

.InputFile section > div p {
  display: block;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  color:#3B89E2;
  font-size: 1.5em;
}

.InputFile section > div input {
  width: 100%;
  height: 100%;
}

.InputFile section > div .ui.button:disabled {
  background-color: transparent !important;
  opacity: 0.3 !important;
}

.InputFile .counter {
  font-size: 0.8em;
  position: absolute;
  top: -2em;
  right: 0;
}

.InputFile .counter.full {
  color: $green;
}

.InputFile .files {
  display: flex;
  position: absolute;
  bottom: 1.3em;
}

.InputFile .files div {
  position: relative;
  width: 8em;
  height: 6em;
  font-size: 0.6em;
  border: 1px solid rgba($color: #000000, $alpha: 0.24);
  padding: 0.5em;
  margin-right: 0.5em;
}

.InputFile .files span {
  display: block;
  position: absolute;
  bottom: 0px;
  left: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  padding: 0 0.5em;
}

.InputFile .files .ui.button {
  position: absolute;
  right: 0;
  padding: 0;
  min-width: unset;
  background-color: transparent;
}
