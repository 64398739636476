.ui.dropdown.title > .text {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  margin-bottom: 4px;
}
.ui.dropdown > .dropdown.icon {
  opacity: 0.87 !important;
}
.ui.form textarea {
  height: 150px;
}
