.purchase-detail-cell {
  display: flex;
  padding: 1em;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}
.purchase-detail-cell:last-child {
  border-bottom: none;
}
@media only screen and (max-width: 426px) {
  .purchase-detail-text {
    white-space: pre-line;
  }
}

.oldPrice {
  text-decoration: line-through;
}

.with-additional-information {
  border-bottom: none;
  padding-bottom: 0;
}

.additional-information {
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  padding-bottom: 1rem;
  padding-right: 1rem;
  text-align: right;
  font-size: 9px;
  padding-top: 6px;
}
