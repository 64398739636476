.AffiliateItem:hover {
  cursor: pointer;
}
.affiliate-item {
  position: relative;
}
.affiliate-item-buyer-header {
  align-items: center;
  position: absolute;
  width: 100%;
  top: 5em;
  padding: 1em;
}
@media only screen and (max-width:426px) {
	.affiliate-item-buyer-header {
    top: 10em;
  }
}
.affiliate-item-buyer-table-header {
  display: flex;
  padding: 1em;
  padding-bottom: 0.5em;
}
.affiliate-item-buyer-table-row {
  align-items: center;
  display: flex;
  padding: 1em;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}
.affiliate-item-buyer-table-cell {
  flex: 1;
  margin: 0 1em;
}
@media only screen and (max-width:426px) {
	.affiliate-item-buyer-table-row {
    display: block;
  }
  .affiliate-item-buyer-table-header {
    display: block;
  }
}


.toggle {
  display: none;
}
.affiliate-item-buyer-header,
.affiliate-item-buyer-content {
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: all 0.2s;
}
// .affiliate-item-buyer-header>i.icon {
//   display: contents;
// }
.affiliate-item-buyer-header>i.icon:after, .affiliate-item-buyer-header>i.icon:before {
  content: '';
  position: absolute;
  // right: 1.25em;
  // top: 0.25em;
  transition: all 0.2s;
  color: #373737;
}
.affiliate-item-buyer-header>i.icon:after {
  transform: rotate(90deg);
}

.affiliate-item-buyer-content {
  max-height: 0;
  overflow: hidden;
}

.toggle:checked {
  & + .affiliate-item-buyer-header + .affiliate-item-buyer-content {
    max-height: 100%;
  }
  & + .affiliate-item-buyer-header>i.icon:before {
    transform: rotate(90deg) !important;
  }
}
