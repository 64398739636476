.input-icons i {
  position: absolute;
}

.input-icons {
  width: 100%;
  cursor: pointer;
}

.input-icons .icon {
  right: 6px;
  top: 10px;
  font-size: 12px;
}

.input-icons.mini .icon {
  right: 6px;
  top: 12px;
  font-size: 12px;
}

.input-icons > .input {
  text-align: left !important;
}

.input-icons > .ui.basic.button,
.ui.basic.buttons .button {
  font-size: 14px !important;
}
.input-icons > .ui.basic.button:hover,
.ui.basic.buttons .button:hover {
  color: rgba(0, 0, 0, 0.6) !important;
}
.input-icons > .ui.basic.button:focus,
.ui.basic.buttons .button:focus {
  color: rgba(0, 0, 0, 0.6) !important;
}
.input-icons > .ui.basic.button:active,
.ui.basic.buttons .button:active {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.Calendar__sectionWrapper {
  height: 25.3em;
}

.datepicker-footer {
  display: flex;
}

.datepicker-footer-button {
  width: 50%;
}
