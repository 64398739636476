.ItemInfo {
  border: 1px solid #DDD;
  padding: 1em;
  display: flex;
  align-content: center;
  margin-bottom: 1em;
}

.ItemInfo .Avatar .ui.spaced.image {
  margin-right: 1em;
}

.ItemInfo .item-content {
  flex-grow: 1;
}

.ItemInfo .item-right-content {
  margin: auto;
  text-align: center;
  white-space: pre-line;
}

.GroupInfo {
  border-top: 1px solid #DDD;
  border-bottom: 1px solid #DDD;
  padding: 1.5em 0;
  display: flex;
  align-content: center;
  margin-bottom: -1px;
}

.GroupInfo .Avatar .ui.spaced.image {
  margin-right: 1em;
}

.GroupInfo .item-content {
  flex-grow: 1;
}

.GroupInfo .item-right-content {
  margin: auto;
  text-align: center;
  white-space: pre-line;
}
