.CopyButton {
  cursor: auto;
  white-space: pre-line;
  display: flex;
  max-width: 400px;
  height: 33px;
}

.CopyButton textarea {
  flex: 1;
  overflow: hidden;
  font-size: 16px;
  color: #0000008a;
  border: 0.5px solid #00000029;
  padding-top: 7px;
  resize: none;
}
.CopyButton textarea:focus {
  outline: -webkit-focus-ring-color auto 1px;
}
@media only screen and (max-width: 766px) {
  .CopyButton {
    padding-bottom: 0;
    margin-left: initial;
  }
  .CopyButton textarea {
    width: 102%;
  }
}
