@import "styles/definitions.scss";

.ui.popup {
  border-radius: 4px;
  margin: 0 !important;
  z-index: 1000000;
}

// MENU
.ui.menu {
  margin: 0;
}
.ui.menu .item {
  font-family: $specialFont;
  color: rgba($color: #000000, $alpha: 0.54);
}
.ui.menu .item .text {
  font-size: 1.125rem;
  flex: 1;
}
.ui.menu .ui.dropdown .menu > .item {
  padding: 0.785714em 4.142857em 0.785714em 1.142857em !important;
}
.ui.menu .ui.dropdown .menu > .item:hover {
  border-radius: 4px !important;
}
.ui.secondary.menu .item {
  padding: 0;
  margin-right: 0;
  margin-left: 1em;
  letter-spacing: -0.27px;
  color: $black;
  font-family: $commonFont;
}
@media only screen and (max-width: 426px) {
  .ui.secondary.menu .item {
    margin-left: 0;
  }
}
.ui.secondary.menu .dropdown.item:hover,
.ui.secondary.menu .link.item:hover,
.ui.secondary.menu a.item:hover {
  background: none;
}
.ui.secondary.pointing.menu {
  border-bottom: 2px solid rgba(34, 36, 38, 0.06);
  font-size: 16px;
}
.ui.secondary.pointing.menu .item {
  margin-right: 1em;
  padding: 0 0 0.8em;
  letter-spacing: -0.27px;
  border-bottom-width: 3px;
  color: $black;
  font-family: $commonFont;
}
.ui.secondary.pointing.menu .active.item {
  font-weight: 700;
  border-color: $green;
  color: $green;
}
.ui.secondary.pointing.menu .active.item:hover {
  border-color: $green;
  color: $green;
}
.ui.top.right.pointing.dropdown > .menu {
  margin: 1em 1em 0 !important;
  border-radius: 4px !important;
  z-index: 999;
}
.ui.tab.active,
.ui.tab.open {
  border: 0;
  padding: 0;
}
.ui.menu .ui.dropdown .menu > .item:first-child {
  padding-top: 1.3em !important;
}
.ui.dropdown > .text {
  font-size: 1rem !important;
  color: #000000;
}

.ui.dropdown > .dropdown.icon {
  opacity: 0.68;
}

.ui.pagination.menu {
  border-radius: 4px;
  box-shadow: none;
}
