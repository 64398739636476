.ResultBox .PriceSession {
  text-align: center;
  font-size: large;
  padding-top: 1em;
}
.ResultBox .CodeSession {
  text-align: center;
  font-size: large;
  color: rgba($color: #000000, $alpha: 0.54);
  padding: 1em 0.7em;
  white-space: pre-line;
  word-wrap: break-word;
}

.payment-bank-slip-tips {
  padding: 1em;
}

.payment-bank-slip-tips-content {
  background-color: #fef9ed;
  padding: 2em;
  margin: auto;
  max-width: 721px;
}
