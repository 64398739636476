.WithdrawForm .number-input {
  position: relative;
}

.WithdrawForm .number-input .carrier-icon {
  position: absolute;
  right:1em;
  top: 0;
  display: flex;
  height: 100%;
  width: 2.1em;
}

.WithdrawForm .number-input .carrier-icon img {
  object-fit: contain;
}
