.Avatar {
  display: flex;
  align-items: center;
}

.Avatar .avatar-content {
  display: inline-block;
}

.Avatar .text {
  white-space: pre-line;
}

.Avatar .ui.avatar.image, 
.Avatar .ui.avatar.image img, 
.Avatar .ui.avatar.image svg, 
.Avatar .ui.avatar.images .image, 
.Avatar .ui.avatar.images img, 
.Avatar .ui.avatar.images svg {
  object-fit: cover;
}
.Avatar .ui.avatar.medium.image, 
.Avatar .ui.avatar.medium.image img, 
.Avatar .ui.avatar.medium.image svg, 
.Avatar .ui.avatar.medium.images .image, 
.Avatar .ui.avatar.medium.images img, 
.Avatar .ui.avatar.medium.images svg {
    width: 3em;
    height: 3em;
}

.Avatar .ui.avatar.small.image, 
.Avatar .ui.avatar.small.image img, 
.Avatar .ui.avatar.small.image svg, 
.Avatar .ui.avatar.small.images .image, 
.Avatar .ui.avatar.small.images img, 
.Avatar .ui.avatar.small.images svg {
    width: 2.5em;
    height: 2.5em;
}

.GroupAvatar {
  display: flex;
  align-items: center;
}

.GroupAvatar .avatar-content {
  display: inline-block;
}

.GroupAvatar .text {
  white-space: pre-line;
}

.GroupAvatar .ui.spaced.image {
  margin-right: 1em;
}

.GroupAvatar .ui.avatar.image, 
.GroupAvatar .ui.avatar.image img, 
.GroupAvatar .ui.avatar.image svg, 
.GroupAvatar .ui.avatar.images .image, 
.GroupAvatar .ui.avatar.images img, 
.GroupAvatar .ui.avatar.images svg {
  border-radius: 4px;
}

.GroupAvatar .ui.avatar.image {
  width: 102px;
  height: 80px;
}

.GroupAvatar .ui.avatar.image img, 
.GroupAvatar .ui.avatar.image svg, 
.GroupAvatar .ui.avatar.images .image, 
.GroupAvatar .ui.avatar.images img, 
.GroupAvatar .ui.avatar.images svg {
  width: 100%;
  height: 100%;
}
