@import 'styles/definitions.scss';

.CreditCardForm .number-input {
  position: relative;
}

.CreditCardForm .number-input .carriers {
  top:-1.5em;
  right:0;
  display:flex;
  flex-direction: row-reverse;
  position: absolute;
}

.CreditCardForm .number-input .carriers .carrier-icon {
  display: flex;
  height: 100%;
  width: 2.1em;
  
  align-items: center;
  text-align: center;
  padding: 2px;
  margin-left: 2px;
  opacity: 0.2;
  transition: opacity 0.3s;
}

.CreditCardForm .number-input .carriers .carrier-icon.active {
  opacity: 1;
  border: 0.5px solid $green;
  transition: opacity 0.3s;
}


.CreditCardForm .number-input .carriers .carrier-icon img {
  object-fit: contain;
  margin: auto;
}
