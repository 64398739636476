aside.emoji-picker-react {
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}
.ui.basic.button.emoji {
  color: rgba(0, 0, 0, 0.9) !important;
}
.ui[class*="right labeled"].icon.button {
  padding: 0.85em 2.6em 0.85em 1em !important;
}
