.InputOption {
  display: flex;
  padding: 0.7em 0.7em;
  align-items: center;
  cursor: pointer;
}

.InputOption:hover .label {
  transition-duration: 100ms;
  color: #000000;
}

.InputOption .bullet {
  width: 1em;
  height: 1em;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 2em;
  background-color: #FFF;
  margin-left: 0.25em;
}

.InputOption .label {
  margin-left: 0.5em;
}

.InputOption img,
.InputOption svg {
  width: 1em;
  height: 1em;
  margin-left: 0.5em;
}

.InputOption.selected .bullet {
  background-color: var(--primary-color);
}
