@import "styles/definitions.scss";

// TEXTS
.text {
  font-size: 1em;
  font-weight: normal;
  letter-spacing: -0.27px;
  color: rgba($color: #000000, $alpha: 0.54);
}
.text.creditCard {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: normal;
  color: rgba($color: #000000, $alpha: 0.87);
}

.text.bold {
  font-weight: bold;
}
.text.green {
  color: $green;
}
.text.red {
  color: $red;
}
.text.yellow {
  color: $yellow;
}
.text.black {
  color: $black;
}
.text.blackDark {
  color: $blackDark;
}
.text.blue {
  color: $blue;
}
.text.orange {
  color: $orange;
}
.text.purple {
  color: $purple;
}
.text.white {
  color: white;
}
.text.grey {
  color: rgba($color: #000000de, $alpha: 0.54);
}
.text.compact {
  font-family: $specialFont;
}
.text.right {
  text-align: right;
}
.text.center {
  text-align: center;
}
.text.massive {
  font-size: $textFontSizeMassive;
  line-height: 1em;
  letter-spacing: -0.75px;
}
@media only screen and (max-width: 450px) {
  .text.massive {
    font-size: 4em;
    line-height: 1em;
    letter-spacing: -0.75px;
  }
}
.text.extra {
  font-size: $textFontSizeExtra;
  line-height: 1.2em;
}
@media only screen and (max-width: 450px) {
  .text.extra {
    font-size: 3em;
    line-height: 1em;
  }
}
.text.huge {
  font-size: $textFontSizeHuge;
  line-height: 1.1em;
  letter-spacing: -0.75px;
}
.text.big {
  font-size: $textFontSizeBig;
  letter-spacing: -0.37px;
  line-height: 1.2em;
}
.text.large {
  font-size: $textFontSizeLarge;
  line-height: 1.5em;
}
.text.normal {
  font-size: $textFontSizeNormal;
  letter-spacing: -0.27px;
  line-height: 1.4em;
}
.text.small {
  font-size: $textFontSizeSmall;
}
.text.tiny {
  font-size: $textFontSizeTiny;
}
h1.ui.header {
  font-size: 5.3125rem;
}
h2.ui.header {
  font-size: 2.25rem;
}
h3.ui.header {
  font-size: 1.5em;
}
h4.ui.header {
  font-size: 1.125em;
}
h5.ui.header {
  font-size: 1rem;
}
.ui.header {
  font-family: ObjectSans, Regular, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: normal;
  padding-bottom: 0.3em;
  white-space: pre-line;
  letter-spacing: -0.75px;
}

h1.ui.header .sub.header {
  letter-spacing: -0.017em;
  font-size: 20px;
  margin-top: 1.5em;
  font-weight: 0;
  color: rgba($color: #000000, $alpha: 0.54);
}

h2.ui.header .sub.header {
  letter-spacing: -0.017em;
  font-size: 18px;
  line-height: 25px;
  margin-top: 1.5em;
  font-weight: 0;
  color: rgba($color: #000000, $alpha: 0.54);
}

h3.ui.header .sub.header {
  letter-spacing: -0.017em;
  font-size: 16px;
  line-height: 22px;
  margin-top: 1.5em;
  font-weight: 0;
  color: rgba($color: #000000, $alpha: 0.54);
}

@media only screen and (max-width: 450px) {
  h1.ui.header {
    font-size: 2.5rem;
  }
  h2.ui.header {
    font-size: 1.8rem;
    line-height: 1em;
  }
  h1.ui.header .sub.header {
    font-size: 16px;
    line-height: 24px;
    margin-top: 1.5em;
  }
  h2.ui.header .sub.header {
    font-size: 16px;
    line-height: 24px;
    margin-top: 1.5em;
  }
}

.ui.card > .content > .header,
.ui.cards > .card > .content > .header {
  font-family: $commonFont;
}
.ui.message {
  border-radius: 4px;
}

.cp.disabled {
  opacity: 0.54;
}

.ui.message a,
.ui.header a,
.text a {
  color: $green;
  text-decoration: underline;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ui.table:not(.unstackable) tr {
  @media only screen and (max-width: 767px) {
  }
}
