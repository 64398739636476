.group-status-label {
  padding: 5px;
  border-radius: 4px;
  font-weight: 400;
  line-height: 20px;
  font-family: var(--secondary-font);

  &.is-draft {
    background: rgba(33, 150, 243, 0.08);
    color: #2196f3;
    &:hover {
      opacity: 0.7;
    }
  }

  &.is-waitlisted {
    background: rgba(248, 226, 61, 0.34);
    color: #74680e;
    &:hover {
      opacity: 0.7;
    }
  }

  &.is-selling {
    background: rgba(76, 175, 80, 0.08);
    color: #4caf50;
    &:hover {
      opacity: 0.7;
    }
  }

  &.is-notenabled {
    background: #f3f3f3;
    color: #00000099;
    &:hover {
      opacity: 0.7;
    }
  }
}

.group-status-button {
  height: 36px;
  margin-top: 0px !important;
  text-align: center !important;
  display: flex !important;
  align-items: center;
  flex-direction: row;
  padding: 0px !important;
  font-family: var(--secondary-font);

  &.readOnly {
    pointer-events: none;
    cursor: none !important;
    border: none !important;
    .dropdown-icon {
      display: none;
    }
  }

  img {
    width: 17px;
    margin: 10px;
  }

  .text {
    order: 2;
  }

  .main-icon {
    order: 1;
    padding: 0 !important;
    height: 17px !important;
    color: transparent !important;
  }

  .menu-icon {
    margin: -2px 8px 0px 0 !important;
  }

  .dropdown-icon {
    order: 3;
    margin-left: auto;
  }

  .menu {
    width: 100% !important;
  }
}
